:root {
  --primary-light: #59439f;
  --primary: #1d1634;
  --primary-dark: #000000;
  --primary-text: #fff;
  --primary-light-text: #fff;
  --primary-dark-text: #fff;
  --secondary-light: #b54d2e;
  --secondary: #3b190f;
  --secondary-dark: #000000;
  --secondary-text: #fff;
  --secondary-light-text: #fff;
  --secondary-dark-text: #fff;
  --tertiary-light: #2eb54d;
  --tertiary: #0f3b19;
  --tertiary-dark: #000000;
  --tertiary-text: #fff;
  --tertiary-light-text: #000;
  --tertiary-dark-text: #fff;
}