.folderCards.default {
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  min-width: 190px;
  min-height: 190px;
  border: 10px solid white;
  text-decoration: none;
  color: white;
  transition: 500ms;
}
.folderCards.default:hover {
  transform: scale(1.1);
  transition: 500ms;
}
.folderCards.default h2 {
  background-color: rgba(29, 22, 52, 0.6);
  backdrop-filter: blur(10px);
  border-radius: 10px 10px 0 0;
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .folderCards.default {
    min-width: calc(50% - 10px);
    min-height: calc(50vw * 0.9 - 10px);
  }
  .folderCards.default h2 {
    font-size: 1em;
  }
}

.folderCards.default .typeDescription {
  width: 100%;
  background-color: rgba(29, 22, 52, 0.6);
  backdrop-filter: blur(10px);
  color: var(--primary-text);
  padding: 5px 10px;
  font-size: 0.6em;
  text-align: center;
  border-radius: 0 0 10px 10px;
}

.folderCards.default.backend {
  background-image: url("../../assets/media/backendIMG.webp");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.folderCards.default.fullstack {
  background-image: url("../../assets/media/fullstackIMG.webp");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.folderCards.app {
  border-radius: 20px;
  padding: 10px;
  min-width: 270px;
  min-height: 577px;
  border: 5px solid #333;
  text-decoration: none;
  color: white;
  transition: 500ms;
  margin: 10px 10px;
}

.folderCards.app.MyBudget {
  display: block;
  background: url("../../assets/projects/myBudgetScreen.png");
  background-size: "cover";
  background-position: "center";
  background-repeat: "no-repeat";
}
