@import "./colors.css";
@import "./font.css";
@import "./buttons.css";

* {
  margin: 0;
  padding: 0;
}

body {
  max-height: 100vh;
  overflow: hidden;
}

/*********** Navigation **********/
.devtool.navbar {
  display: flex;
  align-items: center;
  position: relative;
  background-color: var(--primary);
  box-sizing: border-box;
  height: 70px;
  padding: 0px 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 101;
}

.devtool.navcontent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.devtool.navbar ul {
  display: flex;
  list-style-type: none;
}

.devtool.navbar ul li {
  display: flex;
  align-items: center;
}

.devtool.navbar ul li a {
  display: block;
  text-decoration: none;
  color: var(--primary-dark-text);
  padding: 20px;
}

.devtool.navbar ul li.active a {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.devtool.navbar ul li:not(:last-child)::after {
  content: "\0000B7";
  color: white;
  font-size: 2em;
  font-weight: bold;
}

.devtool.undis {
  display: none;
}

@media only screen and (max-width: 800px) {
  .devtool.language-switch {
    background-color: var(--primary);
  }
  .devtool.navcontent {
    width: 100%;
    display: none;
    position: absolute;
    top: 100%;
    left: 0px;
    background-color: var(--primary-light);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .devtool.navcontent.toggle {
    display: flex;
  }
  .devtool.toggleBtn {
    display: block !important;
    height: 70px;
    line-height: 75px;
    background-color: rgba(0, 0, 0, 0);
    padding: 0 10px;
    border: none;
    content: "menu";
    color: white;
    position: absolute;
    top: 0;
    right: 0px;
    font-size: 1.6em;
    font-weight: bolder;
    cursor: pointer;
  }
  .devtool.flex.column-800 {
    flex-direction: column;
  }
  .devtool.navbar .logo {
    display: block;
    font-size: 1.1em;
    color: var(--primary-dark-text);
  }
}

@media only screen and (max-width: 600px) {
  .devtool.navbar ul li.active {
    display: none;
  }
}

@media only screen and (max-width: 450px) {
  .devtool.navbar ul li a {
    padding: 20px 10px;
  }
}

/********** Footer **********/
.devtool.footer {
  width: 100vw;
}

/********** global devtool rules ***********/
.devtool.container.w-100 {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0 !important;
  padding-top: 200px !important;
  overflow: scroll;
}
.devtool.borderR10 {
  border-radius: 10px;
}
.test {
  background-color: green !important;
}
.test2 {
  background-color: red !important;
}
.devtool.flex {
  display: flex;
}
.devtool.gap20 {
  gap: 20px;
}
.devtool.wrap {
  flex-wrap: wrap;
}
.devtool.fixed.bt0lft0 {
  position: absolute;
  bottom: 0;
  left: 0;
  color: var(--primary-dark-text);
}
.devtool.fixed.bt0lft10 {
  position: absolute;
  bottom: 10px;
  left: 0;
  color: var(--primary-dark-text);
}
.devtool.flex.column {
  flex-direction: column;
}
.devtool.startX.betY {
  height: calc(100% - 280px);
  flex-grow: 1; /* Prend toute la place restante */
  align-items: flex-start;
  justify-content: space-between;
}
.devtool.centerX {
  justify-content: center;
}
.devtool.centerX.betY {
  justify-content: space-between;
  align-items: center;
}
.devtool.centerXY {
  justify-content: center;
  align-items: center;
}
.devtool.betweenX {
  justify-content: space-between;
}
.devtool.footer .right {
  color: var(--primary-dark-text);
  z-index: 2;
}
.devtool.txt-center {
  width: 100%;
  text-align: center;
}
.pointerEventOff {
  pointer-events: none;
}

.wh-100 {
  width: 100vw;
  height: 100% !important;
}

.devtool.pos-relative {
  position: relative;
}

.devtool.pos-absolute {
  position: absolute;
}

.devtool.pos00 {
  top: 0;
  left: 0;
}

.devtool.pos01 {
  bottom: 0;
  left: 0;
}

.devtool.d-blc {
  display: block;
}
.devtool.p20 {
  padding: 20px;
}

.devtool.pt20 {
  padding-top: 20px;
}

.devtool.pb20 {
  padding-bottom: 20px;
}

.devtool.pr20 {
  padding-right: 20px;
}
.devtool.pl20 {
  padding-left: 20px;
}
.devtool.m20 {
  margin: 20px;
}

.devtool.mt20 {
  margin-top: 20px;
}

.devtool.mb20 {
  margin-bottom: 20px;
}

.devtool.mr20 {
  margin-right: 20px;
}
.devtool.ml20 {
  margin-left: 20px;
}
