.container {
  width: 100%;
}
.content {
  position: relative;
  top: calc(5vw);
  width: 80%;
  height: max-content;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(5px);
  padding: 10px;
  animation: monter 1s ease forwards;
  z-index: 2;
  margin-bottom: 40vh;
}

.aboutMe {
  display: flex;
  align-items: flex-start;
  padding: 3vw;
  gap: 5vw;
}

.aboutMe h1 {
  font-size: 2em;
  margin-bottom: 30px;
  color: var(--primary);
}

.aboutMe h1 span {
  display: block;
  font-size: 0.5em;
  font-weight: 300;
}

.aboutMe img {
  width: 160px;
  height: 200px;
  object-fit: scale-down;
}

.aboutMe p {
  padding-top: 10px;
  text-align: justify;
}

.aboutMe p > .under-title {
  display: block;
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 700px) {
  .content {
    width: 85%;
    top: -15vw;
  }
  .aboutMe {
    flex-direction: column;
    align-items: center;
  }
  .aboutMe h1 {
    font-size: 1.5em !important;
    margin-bottom: 10px;
    text-align: center;
  }
}

@media only screen and (max-width: 450px) {
  .content {
    width: 95%;
    top: -120px;
  }
  .aboutMe h1 {
    font-size: 1.3em !important;
  }
}
