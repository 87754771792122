.devtool.language-switch {
  position: relative;
  width: 90px;
  height: 50px;
  background-color: var(--primary-light);
  border-radius: 30px;
  cursor: pointer;
  overflow: hidden;
  padding: 5px;
  box-sizing: border-box;
  margin: 5px;
}

.devtool.language-switch-toggle {
  position: absolute;
  left: 5px; /* Correspond à la marge extérieure */
  top: 5px; /* Correspond à la marge extérieure */
  width: calc(50% - 5px); /* Soustrait la marge */
  height: calc(100% - 10px); /* Soustrait les marges haut et bas */
  background-color: white;
  border-radius: 50%; /* Cercle parfait */
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.devtool.language-switch-toggle.switched {
  transform: translateX(100%);
}

.devtool.flag {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border-radius: 4px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
.link {
  font-size: 1.4rem;
  font-weight: bold;
  color: white;
  text-decoration: none !important;
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  display: block;
}
.link span,
.link i {
  position: relative;
}
.link i {
  margin-left: 0.5em;
}
.link .color {
  width: 2em;
  height: 2em;
  border-radius: 2em;
  background-color: var(--primary-light);
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  left: -0.65em;
}

@media only screen and (max-width: 700px) {
  .link {
    font-size: 1rem;
  }
}