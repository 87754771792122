.chartsContainer {
  display: flex;
  flex-wrap: wrap;
}

.skillsSec h1 {
  font-size: 2em;
  margin: 30px 0 0 50px;
  color: var(--primary);
}

.recharts-responsive-container {
  height: 35vh !important;
  width: calc(100% / 3) !important;
  overflow: visible;
  position: relative;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recharts-responsive-container .label {
  position: absolute;
  bottom: 0;
  width: 90%;
  max-width: 270px;
  text-align: center;
  background-color: var(--primaryClear2);
  color: var(--primary);
  border: 2px solid var(--primary);
  padding: 5px;
  clip-path: polygon(15% 0, 85% 0, 100% 100%, 0 100%);
}

.recharts-text {
  fill: var(--primary) !important;
  width: min-content;
}
.recharts-radar path {
  stroke: var(--primary);
  fill: var(--primaryClear) !important;
}

@media only screen and (max-width: 900px) {
  .recharts-responsive-container {
    width: 50% !important;
  }
}

@media only screen and (max-width: 700px) {
  .recharts-responsive-container {
    width: 100% !important;
  }
  .chartsContainer {
    flex-direction: column;
    align-items: center;
  }
  .skillsSec h1 {
    font-size: 1.6em !important;
    text-align: center;
    margin: 30px 0;
  }
}

@media only screen and (max-width: 450px) {
  .skillsSec h1 {
    font-size: 1.2em !important;
  }
}
