.folder .content {
  padding-top: 110px !important;
}

.folder .content h1 {
  background-color: var(--primary);
  color: white;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  position: absolute;
  top: -30px;
  left: 0;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.folder .container {
  justify-content: flex-start;
}

@media only screen and (max-width: 700px) {
  .folder .content h1 {
    font-size: 2em;
  }
}

@media only screen and (max-width: 494px) {
  .folder .content h1 {
    text-align: center;
    margin: 30px 0 50px 0;
  }
}
