.contactBand {
  width: calc(100% + 40px);
  position: relative;
  right: 20px;
  background-color: var(--primary);
  box-sizing: border-box;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.48) 6px 2px 16px 0px,
    rgba(0, 0, 0, 0.8) -6px -2px 16px 0px;
  margin-top: 80px;
  margin-bottom: 30px;
}

.contactBand .wrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 40px 0;
}

@media only screen and (max-width: 450px) {
  .contactBand .wrapper {
    flex-direction: column;
    align-items: self-start;
    gap: 30px;
    padding: 0 10px;
  }
}
