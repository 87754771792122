.contactSec .content > div {
  width: 100%;
}

.headerSec {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding: 0 0 0 5vw;
  gap: 40px;
}

.headerSec p {
  width: 100%;
}

.headerSec span.title {
  font-weight: bold;
  display: block;
  margin-top: 30px;
  font-size: 2em;
  color: var(--primary);
}

.headerSec a.link {
  margin: 30px 0 0 20px;
  width: max-content;
}

.headerSec img {
  width: 160px;
  height: 200px;
  object-fit: scale-down;
}

.education {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  margin-top: 30px;
  gap: 20px;
}
.education > div {
  padding: 10px;
  height: 380px;
}
.education .dwwm > div:first-child {
  margin-left: 20px;
}
.education ul {
  list-style-type: square;
}

.education ul li {
  margin-left: 20px;
}

.education .dwwm ul li {
  margin-left: 30px;
}

.education > div {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  background-color: var(--primaryClear2);
  color: white;
  border: 2px solid white;
  border-radius: 10px;
}
.education .logo {
  position: relative;
  top: 80px;
  width: 200px;
  height: 80px;
  margin-right: -20px;
  background-color: rgb(49, 47, 47) !important;
  transform: rotate(-90deg);
  border: 4px solid rgb(49, 47, 47);
  border-radius: 4px;
}
.education .dcl .logo {
  background: url("../../assets/openclassroom.png.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.education .dwwm .logo {
  background: url("../../assets/popschoollogo.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
}

.mooc {
  margin: 20px 0 0 0;
  padding: 20px;
}

.mooc h1 {
  font-size: 2em;
  margin: 30px 0 0 30px;
  color: var(--primary);
  width: 100%;
}

.mooc .list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
}

.mooc .list > div {
  width: calc(100% / 3 - 20px);
}

.mooc .list h2 {
  font-size: 1.4em;
}

.mooc .list ul {
  margin-left: 30px;
  margin: 20px;
  list-style-type: square;
}

@media only screen and (max-width: 1800px) {
  .education {
    flex-direction: column;
  }
  .education > div {
    width: 100%;
    height: auto;
    max-width: 900px;
  }
  .education .dwwm > div:first-child {
    margin-left: 0;
  }
}

@media only screen and (max-width: 1500px) {
  .mooc .list > div {
    width: calc(100% / 2 - 20px);
  }
}

@media only screen and (max-width: 900px) {
  .headerSec h1 {
    font-size: 1.4em;
  }
  .mooc .list > div {
    width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .headerSec {
    flex-direction: column-reverse;
    align-items: center;
    padding: 0 10px;
  }

  .headerSec h1 {
    margin: 0;
    text-align: center;
  }

  .headerSec p {
    text-align: center;
  }
  .education .logo {
    display: none;
  }
  .mooc {
    padding: 10px;
  }
  .mooc .list {
    flex-direction: column;
  }
  .mooc h1 {
    margin: 30px 0 0 0;
    font-size: 1.6em;
    text-align: center;
  }
}

@media only screen and (max-width: 450px) {
  .headerSec {
    padding: 0 5px;
  }
  .education {
    padding: 10px;
  }
  .mooc h1 {
    font-size: 1.2em;
  }
}
